import 'jquery';

// import './src/classes/InlineFrameAPI')
//import 'responsive-tabs';
//import 'responsive-tabs/css/responsive-tabs.css';

import 'smooth-scroll/src/js/_closest.polyfill';
import 'smooth-scroll/src/js/_customEvent.polyfill';
import 'smooth-scroll/src/js/_requestAnimationFrame.polyfill';
import 'smooth-scroll/src/js/smooth-scroll';

global.get$FromWebpack = function () {
    return jQuery;
};